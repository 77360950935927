var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":`overlay--${_vm.style}-transition`,"mode":"out-in"},on:{"before-enter":function($event){return _vm.onEnterTransitionBegins()},"after-enter":function($event){return _vm.onEnterTransitionEnds()},"after-leave":function($event){return _vm.onLeaveTransitionEnds()}}},[(_vm.isVisible)?_c('div',{class:['overlay', `overlay--${_vm.style}`, `overlay--${_vm.type}`],attrs:{"role":_vm.style === 'popup' ? 'alertdialog' : 'dialog',"aria-modal":"true","aria-label":_vm.data.title || _vm.$__('Ooops!')}},[_c('div',{class:[
				'overlay-container',
				`overlay--${_vm.style}-container`,
				`overlay--${_vm.type}-container`,
			]},[_c('div',{class:[
					'overlay-wrapper',
					`overlay--${_vm.style}-wrapper`,
					`overlay--${_vm.type}-wrapper`,
				]},[(
						_vm.style === 'popup' ||
						_vm.type === 'product-added-to-cart-v2'
					)?_c('button',{ref:"OverlayFocus",staticClass:"overlay-dismiss overlay--popup-dismiss",attrs:{"type":"button","title":_vm.$__('Dismiss alert dialog'),"aria-label":_vm.$__('Dismiss alert dialog')},on:{"keypress":_vm.dismiss,"click":function($event){$event.stopPropagation();return _vm.dismiss.apply(null, arguments)}}},[_c('i',{staticClass:"icon-cross"})]):_vm._e(),_vm._v(" "),_c(_vm.typeComponent || 'div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(!!_vm.type ? _vm.dismiss : () => {}),expression:"!!type ? dismiss : () => {}"}],tag:"Component",class:[
						'overlay-body',
						`overlay--${_vm.style}-body`,
						`overlay--${_vm.type}-body`,
					],attrs:{"data":_vm.typeComponent ? _vm.data : null}},[(!_vm.type)?[_c('h2',{staticClass:"overlay-title",domProps:{"textContent":_vm._s(_vm.data.title || _vm.$__('Ooops!'))}}),_vm._v(" "),_c('div',{staticClass:"overlay-content overlay-content--center overlay-content--wrap"},[_c('div',{domProps:{"innerHTML":_vm._s(
									_vm.data.content ||
									_vm.$__(
										'We apologize, an error has occured while processing your request.'
									)
								)}})]),_vm._v(" "),_c('div',{staticClass:"overlay-ctas"},[_c('button',{staticClass:"overlay-cta overlay-cta--primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.dismiss($event)}}},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s((_vm.data.cta && _vm.data.cta.text) ||
									_vm.$__('Close'))+"\n\t\t\t\t\t\t\t")])])]:_vm._e()],2)],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }