<template>
	<transition
		:name="`overlay--${style}-transition`"
		mode="out-in"
		@before-enter="onEnterTransitionBegins()"
		@after-enter="onEnterTransitionEnds()"
		@after-leave="onLeaveTransitionEnds()"
	>
		<div
			v-if="isVisible"
			:role="style === 'popup' ? 'alertdialog' : 'dialog'"
			aria-modal="true"
			:aria-label="data.title || $__('Ooops!')"
			:class="['overlay', `overlay--${style}`, `overlay--${type}`]"
		>
			<div
				:class="[
					'overlay-container',
					`overlay--${style}-container`,
					`overlay--${type}-container`,
				]"
			>
				<div
					:class="[
						'overlay-wrapper',
						`overlay--${style}-wrapper`,
						`overlay--${type}-wrapper`,
					]"
				>
					<button
						v-if="
							style === 'popup' ||
							type === 'product-added-to-cart-v2'
						"
						ref="OverlayFocus"
						type="button"
						class="overlay-dismiss overlay--popup-dismiss"
						:title="$__('Dismiss alert dialog')"
						:aria-label="$__('Dismiss alert dialog')"
						@keypress="dismiss"
						@click.stop="dismiss"
					>
						<i class="icon-cross" />
					</button>
					<Component
						:is="typeComponent || 'div'"
						v-click-outside="!!type ? dismiss : () => {}"
						:data="typeComponent ? data : null"
						:class="[
							'overlay-body',
							`overlay--${style}-body`,
							`overlay--${type}-body`,
						]"
					>
						<template v-if="!type">
							<h2
								class="overlay-title"
								v-text="data.title || $__('Ooops!')"
							/>
							<div
								class="overlay-content overlay-content--center overlay-content--wrap"
							>
								<div
									v-html="
										data.content ||
										$__(
											'We apologize, an error has occured while processing your request.'
										)
									"
								/>
							</div>
							<div class="overlay-ctas">
								<button
									class="overlay-cta overlay-cta--primary"
									type="button"
									@click="dismiss($event)"
								>
									{{
										(data.cta && data.cta.text) ||
										$__('Close')
									}}
								</button>
							</div>
						</template>
					</Component>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	import dismissByEsc from '@/mixins/dismiss-by-esc.js';
	export default {
		components: {
			OverlayPicture: () => import('./OverlayPicture.vue'),
			OverlayNewsletter: () => import('./OverlayNewsletter.vue'),
			OverlayFeatureList: () => import('./OverlayFeatureList.vue'),
			OverlayProductAddedToCart: () =>
				import('./OverlayProductAddedToCart.vue'),
			OverlayProductAddedToCartV2: () =>
				import('./OverlayProductAddedToCartV2.vue'),
			OverlayProductSizeGuide: () =>
				import('./OverlayProductSizeGuide.vue'),
			OverlayProductSizeSelectMobile: () =>
				import('./OverlayProductSizeSelectMobile.vue'),
			OverlayProductSizeRequest: () =>
				import('./OverlayProductSizeRequest.vue'),
			OverlayNavMobile: () => import('./OverlayNavMobile.vue'),
			OverlayDeliveryInformation: () =>
				import('./OverlayDeliveryInformation.vue'),
			OverlayMagicLink: () => import('./OverlayMagicLink.vue'),
			OverlayRemoveSavedCard: () =>
				import('./OverlayRemoveSavedCard.vue'),
			OverlayInfoPage: () => import('./OverlayInfoPage.vue'),
			OverlayAppPopup: () => import('./OverlayAppPopup.vue'),
			OverlaySubscribePopup: () => import('./OverlaySubscribePopup.vue'),
		},
		mixins: [dismissByEsc],
		data() {
			return {
				canClickOutside: false,
				previouslyFocusedElement: null,
			};
		},
		computed: {
			isOn() {
				if (this.type === 'subscribe-popup') {
					return false;
				}
				return this.$store.state.ui.isOverlayOn;
			},
			isVisible() {
				return !!(this.isOn && this.data);
			},
			data() {
				return this.$store.state.ui.overlayData;
			},
			type() {
				return this.$store.state.ui.overlayType;
			},
			style() {
				switch (this.type) {
					case 'nav-mobile':
						return 'nav';

					case 'product-size-select-mobile':
						return 'sheet';

					case 'product-added-to-cart':
						return 'popup';

					case 'product-added-to-cart-v2':
						return !this.isMobile() ? 'popup' : 'sheet';

					case 'app-popup':
						return 'sheet';

					case 'subscribe-popup':
						return 'subscribe-popup';

					default:
						return 'popup';
				}
			},
			typeComponent() {
				return this.type
					? `Overlay${this.$case(this.type, 'pascal')}`
					: '';
			},
		},
		watch: {
			isOn(val) {
				this.$store.dispatch('ui/toggleBackdrop', val);
			},
			isVisible(val) {
				this.setEscDismission(val);
				if (
					this.type !== 'app-popup' &&
					this.type !== 'subscribe-popup'
				) {
					this.setClickOutside(val);
				}
			},
		},
		methods: {
			dismiss(event) {
				if (
					event &&
					event.code &&
					!['Enter', 'Space'].includes(event.code)
				) {
					return;
				}

				if (this.canClickOutside) {
					this.$store.dispatch('ui/toggleOverlay', false);
				}
			},
			setClickOutside(val) {
				this.$nextTick(() => {
					this.canClickOutside = val;
				});
			},
			onEnterTransitionBegins() {
				this.$store.dispatch('ui/toggleBodyfix', true);

				if (!process.browser) {
					return;
				}

				window.scrollTo(0, 0);
				this.previouslyFocusedElement = document.activeElement;
			},
			onEnterTransitionEnds() {
				if (this.type === 'nav-mobile') {
					this.$store.commit('ui/SET_DATA', {
						isNavMobileBackdropOn: true,
					});
				}
				this.setFocus();
			},
			onLeaveTransitionEnds() {
				if (!this.isOn) {
					this.$store.dispatch('ui/toggleBodyfix', false);
				}
				this.retainFocus();
			},
			setFocus() {
				if (!process.browser) {
					return;
				}

				const element =
					typeof this.$refs.OverlayFocus === 'array'
						? this.$refs.OverlayFocus[0]
						: this.$refs.OverlayFocus;

				if (element) {
					element.focus();
				}
			},
			retainFocus() {
				if (!process.browser) {
					return;
				}

				const element = this.previouslyFocusedElement;

				if (element) {
					element.focus();
				}
			},
			isMobile() {
				if (!process.browser) {
					return;
				}
				return this.$mq?.is('sm-down');
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/overlay.scss';
</style>
